import { Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./podcast.module.css";

const Podcast = ({ post, mode }) => {
  const image = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.altText || ``,
  };
  return (
    <>
      <article
        className={`${s.postItem} ${('small' == mode) ? s.small : ''}`}
      >
        <Link to={post.uri} className={s.postTitle}>
          {image?.data && (
            <GatsbyImage
              loading="eager"
              placeholder="none"
              image={image.data}
              alt={image.alt}
              className={s.postImg}
            />
          )}
          {!image?.data && (
            <StaticImage
              src="../../assets/images/podcast.png"
              alt="podcast"
              className={s.postImg}
            />
          )}
          <h3>{parse(post.title)}</h3>
        </Link>
      </article>
    </>
  );
};

export default Podcast;
