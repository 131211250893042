import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import React from "react"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "./../assets/css/@wordpress/block-library/build-style/style.css"
import "./../assets/css/@wordpress/block-library/build-style/theme.css"
import * as s from "./../assets/css/podcast.module.css"
import Layout from "./../components/layout"
import RelatedPodcastsBlock from './../components/RelatedPodcastsBlock/RelatedPodcastsBlock'
import RelatedPostsBlock from './../components/RelatedPostsBlock/RelatedPostsBlock'
import Seo from "./../components/seo"
import AuthorBio from './../components/author/author';
import schemaBuilder from "../utils/schemaBuilder"

const PodcastTemplate = ({ data: { post, author, site }, location }) => {

  const [showIframe, setShowIframe] = React.useState(false);

  const authorAvatarImage = {
    data: author.users.avatar?.localFile?.childImageSharp?.gatsbyImageData,
    alt: author.users.avatar?.altText || ``,
  };

  const schema = schemaBuilder({podcast: post, author: author}, 'podcast', site);

  return (
    <Layout pageTitle={`Interview - ${post.title}`}>
      <Seo
        title={post.title}
        description={post.excerpt}
        pageSeo={post}
        location={location}
        schema={schema}
      />
      <article className={`${s.podcastTemplate} blog-post`}>
        <section className="container my-4">
          <div className="row">
            <div className={`col-12 col-xxl-8 offset-xxl-2`}>
              <h1>{parse(post.title)}</h1>
            </div>
          </div>
        </section>
        <section className="my-4">
          <div className={`container`}>
            <div className="row">
              <div className="col-12 col-xxl-8 offset-xxl-2">
                <div className={s.breadcrumbs}>
                  <Link to={`/`} className={`${s.beadcrumbItem}`} style={{ minWidth: 'max-content' }}>Home</Link><span className={`${s.beadcrumbItem}`}>/</span>
                  <Link to={`/podcasts/`} className={`${s.beadcrumbItem}`} style={{ minWidth: 'max-content' }}>Podcasts</Link><span className={`${s.beadcrumbItem}`}>/</span>
                  <span className={`${s.beadcrumbItem}`}>{parse(post.title)}</span>
                </div>
              </div>
              <div className="col-12 col-xxl-8 offset-xxl-2">
                <div className={`${s.postDate}`}>
                  {post.date} {!!post?.readingTime?.text && <span> &#8729; {post?.readingTime?.text}</span>}
                </div>
              </div>
            </div>
          </div>
        </section>
        {!!post.content && (
          <section
            className={`${s.postContent} container`}
          >
            <div className="row">
              <div className={`col-12 col-xxl-8 offset-xxl-2`}>
                {!!post.acf_podcast?.iframe && <>
                  {showIframe
                    ? parse(post.acf_podcast?.iframe)
                    : <button className={s.btnListen} onClick={() => {setShowIframe(true)}}>Listen to the podcast</button>
                  }
                </>}
                {parse(post.content)}
              </div>
            </div>
          </section>
        )}
        <section className="my-4">
          <div className={`container`}>
            <div className="row">
              <div className="col-12 col-xxl-8 offset-xxl-2">
                <AuthorBio author={author} />
              </div>
            </div>
          </div>
        </section>
        {post.acf_podcast?.relatedPosts?.length > 0 &&
          <RelatedPostsBlock block={{ posts: post.acf_podcast?.relatedPosts, title: <b className="text-center d-block">Related stories</b> }} />}
        {post.acf_podcast?.relatedPodcasts?.length > 0 &&
          <RelatedPodcastsBlock block={{ podcasts: post.acf_podcast?.relatedPodcasts, title: <b className="text-center d-block">Related podcasts</b> }} />}
      </article>
    </Layout >
  )
}

export default PodcastTemplate

export const podcastQuery = graphql`
  query PodcastById(
    $id: String!
    $authorId: String!
  ) {
    site {
      siteMetadata {
        siteUrl: url
        title
      }
    }
    post: wpPodcast(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      acf_podcast {
        iframe
        relatedPosts {
          post {
            ... on WpPost {
              excerpt
              seo {
                metaDesc
              }
              acf_post {
                heading {
                  cardBackground
                }
              }
              uri
              date(formatString: "MMM DD, YYYY")
              title
              excerpt
              categories {
                nodes {
                  slug
                  name
                }
              }
              readingTime {
                text
              }
              tags {
                nodes {
                  name
                }
              }
              author {
                node {
                  avatar {
                    size
                    url
                  }
                  users {
                    avatar {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                  name
                  slug
                  uri
                }
              }
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
        relatedPodcasts {
          podcast {
            ... on WpPodcast {
              id
              uri
              title
              featuredImage {
                node {
                  __typename
                  ... on WpMediaItem {
                    mediaItemUrl
                    localFile {
                      childImageSharp {
                          gatsbyImageData
                      }
                    }
                    altText
                  }
                }
              }
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    author: wpUser(id: {eq: $authorId}) {
      avatar {
        size
        url
      }
      users {
        avatar {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      name
      description
      email
      slug
      uri
      seo {
        metaDesc
        social {
          facebook
          linkedIn
          twitter
          wikipedia
        }
      }
    }
  }
`
