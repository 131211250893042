// extracted by mini-css-extract-plugin
export var authorSocials = "podcast-module--author-socials--9ba06";
export var beadcrumbItem = "podcast-module--beadcrumb-item--5cf71";
export var beadcrumbs = "podcast-module--beadcrumbs--a5e22";
export var breadcrumbs = "podcast-module--breadcrumbs--23fa0";
export var btnListen = "podcast-module--btn-listen--fcb7e";
export var podcastTemplate = "podcast-module--podcast-template--602be";
export var postAuthorImg = "podcast-module--post-author-img--e5d54";
export var postAuthorName = "podcast-module--post-author-name--a2204";
export var postAuthorWrapper = "podcast-module--post-author-wrapper--27264";
export var postContent = "podcast-module--post-content--923f6";
export var postDate = "podcast-module--post-date--4d45d";