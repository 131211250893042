import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from "react";
import Slider from "react-slick";
import Podcast from './../podcast/podcast';
import * as s from "./RelatedPodcastsBlock.module.css";

const RelatedPodcastsBlock = ({ block }) => {


  const bp = useBreakpoint();

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    // centerMode: true,
    centerPadding: '8px',
  };

  return (
    <>
      <section className={`${s.seoSeoResources}`}>
        <div className="container">
          <div className="row">
            <h2>{block.title}</h2>
          </div>
        </div>
        {bp.sm ?
          <div className="container d-none d-sm-block">
            <div className="row justify-content-center">
              {!!block.podcasts &&
                block.podcasts.map((item, i) => {
                  return <div className="col-sm-12 col-md-6 col-xl-4" key={`related-post-${i}`}>
                    <Podcast post={item.podcast} mode="small" />
                  </div>;
                })
              }
            </div>
          </div>
          :
          <div className="d-sm-none">
            {!!block.podcasts &&
              <Slider {...sliderSettings}>
                {block.podcasts.map((item, i) => {
                  return <div key={`related-slider-post-${i}`} className="px-3"><Podcast post={item.podcast} mode="small" /></div>;
                })}
              </Slider>
            }
          </div>
        }

      </section>
    </>
  );
};

export default RelatedPodcastsBlock;
